import React from "react"
// import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
const resume = "http://source.andrew-osipov.pro/resume.pdf";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`Andrew Osipov`, `frontend javascript developer`]} />
    <h1>Frontend JavaScript Developer</h1>
    <p>Self-driven team player with clear communication</p>
    <p><i>Typescript / Node.js / React.js / Redux / GraphQL / Node.JS / TDD</i></p>
    <p><a href={resume} className="button" download target="_blank">Open my resume</a></p>
  </Layout>
)

export default IndexPage
